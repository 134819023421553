import React from "react"
import Header from "../components/header"

export default function FourZeroFour() {
  return (
    <body>
      <Header headerText="404 Not Found."/>
      <p>
        Damn man, this sucks. You've hit a 404 bro. This really sucks. I'm sorry
        that happened to you bro. Damn this sucks.
      </p>
      <p>
        Hey you know what sometimes works for me? Checking that you repeated the
        same thing in the directory. For example its not 
        "zacharythomas.me/contact" it's "zacharythomas.me/contact/contact". I 
        know it seems stupid! But I promise there's a very sensible reason for 
        the redundancy. 
      </p>
      <p>
        No good? Damn man...this really sucks. 
      </p>
    </body>
  )
}
